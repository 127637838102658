<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st7" d="M61.324,100.703c-27.416,0.009-49.649-22.543-49.635-50.349C11.702,22.525,33.905,0,61.323,0   c27.409,0,49.612,22.519,49.645,50.353C111.001,78.125,88.749,100.693,61.324,100.703z M99.001,52.286   c-0.516,8.64-3.412,16.278-8.924,22.799c-7.017,8.301-15.991,12.839-26.738,13.468c-8.558,0.501-16.499-1.715-23.504-6.786   c-9.795-7.091-15.303-16.756-16.146-28.998c-0.551-8.004,1.172-15.551,5.423-22.318C37.05,17.82,48.584,11.841,63.278,12.044   c0-1.267,0-2.512,0-3.755C48.572,7.383,31.541,15.373,23.58,32.876c-7.308,16.069-4.07,36.265,10.417,49.191   c12.596,11.239,31.781,14.158,47.805,4.905c15.756-9.098,21.775-25.599,20.936-38.412c-9.808,0-19.616,0-29.416,0   c0,1.258,0,2.481,0,3.726C81.877,52.286,90.392,52.286,99.001,52.286z M63.295,68.554c0-13.455,0-26.851,0-40.247   c-1.324,0-2.586,0-3.863,0c0,14.711,0,29.395,0,44.08c4.563,0,9.094,0,13.613,0c0-1.295,0-2.553,0-3.833   C69.8,68.554,66.597,68.554,63.295,68.554z M51.379,34.279c-0.039-3.344-2.622-5.905-5.72-5.994   c-3.431-0.098-6.101,2.694-6.083,6.007c0.018,3.332,2.592,5.941,5.866,5.975C48.735,40.301,51.353,37.588,51.379,34.279z"/>
            <g>
                <path class="st7" d="M203.521,46.409c7.695,0,15.347,0,23.059,0c0,0.18,0,0.344,0,0.508c0,9.118-0.003,18.236,0.01,27.354    c0.001,0.382-0.136,0.582-0.429,0.773c-2.624,1.708-5.505,2.797-8.492,3.615c-4.854,1.329-9.804,1.884-14.825,1.761    c-4.067-0.1-8.061-0.67-11.885-2.162c-6.503-2.537-10.764-7.256-13.053-13.876c-1.161-3.358-1.725-6.834-1.938-10.375    c-0.191-3.178-0.172-6.354,0.188-9.521c0.497-4.365,1.572-8.554,3.776-12.38c2.944-5.111,7.308-8.401,12.816-10.192    c4.284-1.394,8.694-1.778,13.164-1.619c3.622,0.128,7.168,0.689,10.58,1.977c4.008,1.513,7.37,3.902,9.899,7.529    c-2.748,2.565-5.494,5.128-8.226,7.678c-0.695-0.742-1.328-1.478-2.023-2.148c-2.188-2.11-4.831-3.281-7.792-3.707    c-3.171-0.457-6.335-0.419-9.46,0.391c-4.259,1.104-7.12,3.779-8.639,7.953c-0.892,2.453-1.276,5.005-1.399,7.599    c-0.179,3.757-0.123,7.504,0.78,11.184c0.576,2.348,1.519,4.512,3.191,6.29c1.795,1.909,4.046,2.946,6.546,3.486    c2.866,0.618,5.759,0.648,8.662,0.339c1.962-0.209,3.894-0.573,5.784-1.162c0.381-0.119,0.517-0.289,0.515-0.708    c-0.019-3.055-0.01-6.11-0.01-9.165c0-0.166,0-0.331,0-0.537c-3.626,0-7.196,0-10.799,0    C203.521,53.654,203.521,50.051,203.521,46.409z"/>
                <path class="st7" d="M133.385,21.153c4.442,0,8.835,0,13.282,0c0,15.66,0,31.311,0,47.026c9.21,0,18.365,0,27.541,0    c0,3.825,0,7.585,0,11.399c-0.186,0-0.35,0-0.514,0c-13.246,0-26.493-0.003-39.739,0.011c-0.47,0-0.58-0.125-0.58-0.592    c0.012-19.103,0.01-38.207,0.01-57.31C133.385,21.519,133.385,21.352,133.385,21.153z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'lg-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    }
}
}
</script>
